@font-face {
  font-family: "proxima-bold";
  src: url("./assets/font/proxima_ssv/Proxima\ Nova\ Bold.otf");
}

@font-face {
  font-family: "proxima-regular";
  src: url("./assets/font/proxima_ssv/ProximaNova-Regular.otf");
}

*body {
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "proxima-regular";
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
.p-r {
  position: relative !important;
}
li {
  list-style: none;
}
a {
  &:hover {
    text-decoration: none;
  }
}
footer {
  margin-top: auto;
}
.font-bold {
  font-weight: 600;
  font-family: "proxima-bold";
}
.text-purple {
  color: #512da8 !important;
}
.text-white {
  color: white !important;
}
.text-grey {
  color: #7188a4 !important;
}
.bg-purple {
  background-color: #512da8;
}
.bg-green {
  background-color: #3dc498;
}
.font20 {
  font-size: 20px !important;
}

.font24 {
  font-size: 24px;
}

.font12 {
  font-size: 12px;
}
.font18 {
  font-size: 18px;
}
.sm-reverse {
  @media (max-width: 762px) {
    flex-direction: column-reverse;
  }
}
input {
  &::placeholder {
    font-size: 14px;
    color: #cfcfdf !important;
    /* Firefox */
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    color: #cfcfdf !important;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
    color: #cfcfdf !important;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
    color: #cfcfdf !important;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
    color: #cfcfdf !important;
  }
}
.home-wrapper {
  header {
    background: url("./assets/img/banner.png");
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    .tagline {
      font-size: 60px;
      font-weight: 800;
      padding-top: 20vh;
      font-family: "proxima-bold";
      color: #f3f3f3;
      line-height: 1.1;
      @media (max-width: 770px) {
        padding-top: 20vh;
        font-size: 49px;
      }
    }
    .sub-tagline {
      color: #a98af4;
      font-size: 20px;
      color: gray;
    }
    .header-img {
      width: 750px;
      height: 750px;
      object-fit: contain;
      @media (min-width: 1001px) and (max-width: 1300px) {
        width: 600px;
        height: 600px;
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .btnApp {
    background: #512da8;
    color: white;
    border-radius: 50px;
    padding: 0.9rem;
    padding-right: 2rem;
    padding-left: 2rem;
    font-weight: bold;
    align-items: center;
    text-align: center;
    border: none;
  }
  .container-input {
    // width: 100px;
    // height: 100px;
    position: relative;
  }
  .shape1 {
    position: absolute;
    bottom: -43px;
    width: 100px;
    height: 100px;
    left: -12px;
    // z-index: -1;
  }

  .get-started-input {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.6rem 1rem 0.6rem 2rem;
    border-radius: 50px;
    .shape1 {
      position: absolute;
      bottom: -42px;
      width: 80px;
      left: -7px;
      // z-index: -1;
    }
    .btn {
      background: #512da8;
      color: white;
      border-radius: 50px;
      padding: 0.7rem;
      font-weight: bold;
      width: 100%;
      @media (max-width: 770px) {
        padding: 0.3rem;
        font-size: 14px;
      }
    }
    label {
      color: gray;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
    }
    input {
      width: 100%;
      border: none;
      &:focus {
        border: none;
        outline: none;
      }
      &::placeholder,
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      :-ms-input-placeholder {
        color: black !important;
        font-size: 18px !important;
      }
    }
    span {
      font-size: 20px;
      color: #7188a4;
    }
    .w-60 {
      width: 60%;
    }
    .w-40 {
      width: 40%;
    }
  }
  .services {
    position: relative;
    h3 {
      font-weight: bold;
      font-size: 28px;
      font-family: "proxima-bold";
    }
    p {
      font-size: 18px;
    }
    h2 {
      font-size: 34px;
      font-weight: bold;
    }
    img {
      // width: 200px;
      // height: 300px;
      // object-fit: contain;
      // max-width: 100%;
      width: 700px;
      height: 700px;
      object-fit: contain;
      @media (min-width: 1001px) and (max-width: 1300px) {
        width: 600px;
        height: 600px;
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
  .btn-black {
    background: #141414;
    box-shadow: 0px 10px 30px rgba(145, 154, 163, 0.5);
    border-radius: 3px;
    width: 202px;
    height: 54px;
    color: white;
    &:hover {
      background: #141414;
      box-shadow: 0px 10px 30px rgba(145, 154, 163, 0.5);
      border-radius: 3px;
    }
  }
}
.navbar {
  background: rgba(0, 0, 0, 0.17);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.129671);
  @media (max-width: 992px) {
    display: none;
  }
  .navlink {
    color: white;
    font-size: 14px;
    margin-left: 24px;
    font-weight: 600;
    &.active {
      color: #a98af4;
    }
  }
  .nav-logo {
    a {
      color: wheat;
      font-size: 20px;
      white-space: nowrap;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}

.business-prof-category {
  position: relative;
  min-height: 40vh;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
  }
  p {
    color: gray;
    font-size: 18px;
  }
  .icons {
    color: #512da8;
    font-size: 41px;
  }
  .curve {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.btn-contact {
  border: #fff solid 1px;
  border-radius: 36px;
  padding: 12px;
  color: #fff;
  background: transparent;
  transition: all 0.3s;
  &:hover {
    background: #fff;
    border: #a98af4 solid 1px;
  }
}
footer {
  background: url("./assets/img/bg2.png");
  min-height: 50vh;
  background-size: cover;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: white;
  h4 {
    font-size: 25px;
    font-weight: bold;
  }
  h3 {
    font-weight: bold;
    font-size: 30px;
  }
  p {
    color: #ffffffb8;
  }
  a {
    color: #ffffffb8;
    &:active,
    &:hover {
      color: inherit;
    }
  }
}

.contact-wrapper {
  color: white;
  background: black;
  header {
    background: linear-gradient(to bottom, rgba(2, 10, 48, 0.52), #331a6f),
      url("assets/img/brand.png");
    background-size: cover;
    background-attachment: fixed;
    min-height: 74vh;
    background-position: bottom;
    h2 {
      padding-top: 20vh;
      font-size: 64px;
      font-weight: bold;
      @media (max-width: 970px) {
        font-size: 50px;
        padding-top: 25vh;
      }
    }
    p {
      padding-bottom: 10vh;
      font-size: 20px;
      @media (max-width: 770px) {
        font-size: 18px;
        padding-bottom: 5vh;
      }
    }
  }
  .contact-section {
    @media (max-width: 770px) {
      flex-direction: column-reverse;
    }
    h1 {
      font-size: 36px;
      font-family: "proxima-bold";
      font-weight: bold;
      color: #512da8;
    }
    a {
      color: #fff;
      .iconify {
        color: #512da8;
      }
    }
    h2 {
      font-size: 36px;
      font-weight: bold;
      font-family: "proxima-bold";
    }
    input,
    textarea {
      border: none;
      border-bottom: solid 1px white;
      background: transparent;
      border-radius: 0;
    }
  }
  .worker-img {
    width: 100%;
    height: 257px;
    object-fit: cover;
    @media (max-width: 770px) {
      display: none;
    }
  }
}
.click {
  cursor: pointer;
}
.text-underline {
  text-decoration: underline;
}
.trust {
  padding: 10vh 0;
  // background: #7188a426;
  background: #cdbdf514;
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 42px;
    margin-bottom: 0;
    color: #a98af4;
  }
}
.professional {
  p {
    font-size: 18px;
  }
  .prof-img {
    width: 400px;
    max-width: 100%;
    @media (max-width: 770px) {
      width: 266px;
    }
  }
  .overflow-text {
    color: #7188a4;
    white-space: nowrap;
    background: white;
    border-radius: 8px;
    min-width: 200px;
    box-shadow: 0px 10px 30px rgba(145, 154, 163, 0.5);
    width: fit-content;
    padding: 0.5rem 1.8rem;
    position: absolute;
    bottom: 17px;
    left: -14px;
    @media (max-width: 455px) {
      padding: 0.5rem 1rem;
      left: inherit;
      right: -3px;
      bottom: inherit;
      top: 14px;
    }
  }
}
.circle-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  h3 {
    font-size: 110px;
    font-weight: bold;
    font-family: "proxima-bold";
    @media (min-width: 320px) {
      font-size: 25px;
    }
    @media (min-width: 576px) {
      font-size: 50px;
    }
    @media (min-width: 768px) {
      font-size: 80px;
    }
  }
  h5 {
    font-size: 28px;
    @media (min-width: 320px) {
      font-size: 16px;
    }
    @media (min-width: 576px) {
      font-size: 20px;
    }
    @media (min-width: 768px) {
      font-size: 26px;
    }
  }
  .img-part {
    position: relative;
    margin: 0 1.5rem;
    width: 500px;
    height: 500px;
    border: dotted 2px #a98af4;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    @media (min-width: 320px) {
      width: 150px;
      height: 150px;
      margin: 0 1rem;
    }
    @media (min-width: 576px) {
      width: 300px;
      height: 300px;
      margin: 0 1.2rem;
    }
    @media (min-width: 768px) {
      width: 400px;
      height: 400px;
      margin: 0 1.2rem;
    }
    @media (min-width: 992px) {
      width: 500px;
      height: 500px;
      margin: 0 2.5rem;
    }
    .dot {
      background: #a98af4;
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
      position: absolute;
      @media (max-width: 770px) {
        width: 7px;
        height: 7px;
      }
      &.dot1 {
        top: -6px;
        left: 48%;
      }
      &.dot2 {
        top: 37%;
        left: 0;
      }
      &.dot3 {
        bottom: 18%;
        left: 10%;
      }
      @media (max-width: 600px) {
        &.dot1 {
          top: -5px;
          left: 48%;
        }
        &.dot2 {
          top: 33%;
          left: 0;
        }
        &.dot3 {
          bottom: 14%;
          left: 10%;
        }
      }
    }
    .part2 {
      border: solid 1px #7188a4;
      border-radius: 50%;
      width: 300px;
      height: 300px;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0.6rem;
      @media (min-width: 320px) {
        width: 80px;
        height: 80px;
      }
      @media (min-width: 576px) {
        width: 100px;
        height: 100px;
      }
      @media (min-width: 768px) {
        width: 200px;
        height: 200px;
      }
      @media (min-width: 992px) {
        width: 300px;
        height: 300px;
      }
    }
    p {
      font-weight: bold;
      font-size: 20px;
      @media (min-width: 320px) {
        font-size: 8px;
      }
      @media (min-width: 576px) {
        font-size: 10px;
      }
      @media (min-width: 768px) {
        font-size: 16px;
      }
      @media (min-width: 992px) {
        font-size: 20px;
      }
      span {
        color: #7188a4;
      }
    }
  }
}
.faq-wrapper {
  background: black;
  color: white;
  header {
    h2 {
      padding-top: 15vh;
      font-size: 64px;
      font-weight: bold;
      @media (max-width: 770px) {
        font-size: 50px;
        padding-top: 15vh;
      }
    }
    p {
      padding-bottom: 15vh;
      font-size: 20px;
      @media (max-width: 770px) {
        font-size: 18px;
        padding-bottom: 5vh;
      }
    }
  }
}
