.menu-icon {
    position: fixed;
    right: 5vw;
    top: 15px;
    padding: 0;
    color: white;
    display: none;
    z-index: 6;
    cursor: pointer;
    &:active {
      border: none;
      outline: none;
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: rgba(0, 0, 0, 0.8);
    &.overlay-isOpen {
      width: 100%;
      z-index: 5;
      .close {
        padding: 0.5rem 1rem;
        color: white;
        cursor: pointer;
        font-size: 40px;
      }
    }
  }
  .sidebar {
    transition: 0.3s all ease-in-out;
    overflow-y: auto;
    position: fixed;
    width: 0% !important;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (min-width: 992px) {
      width: 271px !important;
      display: block !important;
    }
  
    &.sidebar-isOpen {
      width: 80% !important;
      top: 0;
      z-index: 10;
    }
    .sidebar-wrapper {
      position: absolute;
      background-color: #eceffe;
      background-size: cover;
      color: #9696bb !important;
      width: 271px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .side-logo{
        padding: 1rem;
        a{
          color: #512da8;
          font-size: 20px;
          white-space: nowrap;
        }
        img{
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
      }
    }
  
    .side-item {
      padding: 1.2rem;
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      display: block;
      color: #512da8;
      &.active {
        color: #171e40;
      }
      .btn-contact {
        border: #512da8 solid 1px;
        color: #512da8;
        background: transparent;
        transition: all .3s;
        &:hover {
          background: #512da8;
          border: #a98af4 solid 1px;
          color: #7188a4;
        }
      }
    }
  
    @media (min-width: 992px) {
      display: none !important;
    }
  }
  .nav-logo-2{
    position: absolute;
    left: 3vw;
    top: 12px;
    display: none;
     @media (max-width: 992px) {
      display: block;
    }
    a{
      color: wheat;
      font-size: 20px;
      white-space: nowrap;
    }
    img{
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }